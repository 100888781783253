import React from 'react'
import { Layout, Footer, ContactBanner, Section, SEO } from '../../components'
import { useTranslation } from 'react-i18next'

const RealEstate = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t('titlePages:realEstateAdvice')} />
      <Section title='realEstate:title' text='realEstate:main-text' />
      <ContactBanner />
      <Footer />
    </Layout>
  )
}

export default RealEstate
